var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "locationStatistics", staticClass: "location-statistics" },
    [
      _c("div", { staticClass: "monitoringMap", attrs: { id: "container" } }),
      _c("div", { staticClass: "ipt-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.list,
              expression: "list"
            }
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.list },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.list = $event.target.value
            }
          }
        }),
        _c("button", { on: { click: _vm.render } }, [_vm._v("绘制")])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }