<template>
  <div class="location-statistics" ref="locationStatistics">
    <div id="container" class="monitoringMap"></div>
    <div class="ipt-box">
      <input type="text" v-model="list" />
      <button @click="render">绘制</button>
    </div>
  </div>
</template>

<script>


export default {
  name: "locationStatistics",

  data () {
    return {
      map: null,
      list: null,
    };
  },
  methods: {
    initMap () {
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        resizeEnable: true,
        expandZoomRange: true,
        // center: this.$store.state.user.lnglat,
        features: ['bg', 'road', 'building', 'point'],
        zoom: 12
      })
      this.map = map;
    },
    render () {
      this.map && this.map.clearMap();
      let single = JSON.parse(this.list)
      let arr = [];
      single.map(item => {
        arr.push([item.longitude, item.latitude]);
        let marker = new AMap.Marker({
          position: [item.longitude, item.latitude],
          zIndex: 999,
          size: new AMap.Size(5, 6), // 图标尺寸
          offset: new AMap.Pixel(-2, 0),
          icon: new AMap.Icon({
            size: new AMap.Size(5, 6), // 图标尺寸
            image:"//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png",
            imageSize: new AMap.Size(5, 6)
          }),
        })
        marker.setMap(this.map)
      })
      
      this.$nextTick(() => {
        var polyline = new AMap.Polyline({
          path: arr,
          isOutline: true,
          outlineColor: '#ffeeff',
          borderWeight: 3,
          strokeColor: "#3366FF",
          strokeOpacity: 1,
          strokeWeight: 4,
          // 折线样式还支持 'dashed'
          strokeStyle: "solid",
          // strokeStyle是dashed时有效
          strokeDasharray: [15, 5],
          lineJoin: 'round',
          lineCap: 'round',
          zIndex: 50,
        });
        this.map.add([polyline]);
        this.map.setFitView();
      })
    }
  },
  created () {

  },
  mounted () {
    this.initMap()
  },
  destroyed () {

  },
};
</script>

<style lang="scss" scoped>
.location-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  position: relative;
  .monitoringMap {
    width: 100%;
    height: 100%;
  }
  .ipt-box {
    position: absolute;
    top: 12px;
    left: 24px;
    z-index: 999;
    input {
      height: 46px;
      line-height: 46px;
      width: 200px;
    }
  }
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

// .theme-project-gps {
.location-statistics {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
// }
</style>